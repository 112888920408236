body {
    font-family: Arial, sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ececec;
}


/* Common Styles */
.product-page {
    font-family: 'PT Serif';
    margin-top: 50px; /* Add margin to ensure content starts below the navbar */
    padding: 2rem;
    width: 100%;
}

/* Desktop View */
.desktop-view .top-images {
    display: flex;
    justify-content: space-between;
}

.desktop-view .top-images img {
    width: 32%; /* Adjust width as needed */
    height: auto;
    object-fit: cover;
}

.desktop-view .product-details {
    display: flex;
    height:70vh;
    

}

  .top-info {
    overflow-y: auto;
  }


  .product-info {
    flex: 1;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
/* this ensures that both of these divisions are equal width flexing*/
.product-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

  .product-image img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }

/* styling for Desktop product description */
.top-info h2 {
    font-family: 'PT Serif';
    font-size: 1.5rem; /* Adjust as needed */
    margin-bottom: 0rem; /* Space below the title */
}

.top-info h3 {
    font-family: 'PT Serif';
    font-size: 20px; /* Adjust as needed */
    font-weight: normal;
    margin-bottom: 1rem; /* Space below the title */
}

.top-info p {
    font-family: 'PT Serif';
    margin-bottom: 1rem; /* Space below each paragraph */
}

.top-info .italic {
    font-family: 'PT Serif';
    font-style: italic;
}

.top-info .italic {
    font-family: 'PT Serif';
    font-style: italic;
}

.top-info .indented {
    font-family: 'PT Serif';
    text-indent: 2rem; /* Indentation for the first line */
}

.top-info .italic-model-info {
    font-family: 'PT Serif';
    font-style: italic;
    margin-bottom: 30px;
}


.top-info .indented.italic {
    font-family: 'PT Serif';
    font-style: italic;
    text-indent: 2rem; /* Combine both indentation and italic styling */
}

.mobile-view .disclaimer-font{
    font-size: 12px;
    font-style: italic
}

.desktop-view .disclaimer-font{
    font-size: 16px;
    font-style: italic
}

.bottom-info {
    /* margin-top: 20px; */
  }


.bottom-images {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.bottom-images img {
    width: 48%;
    height: auto;
    object-fit: cover;
}


.mobile-view .main-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-top: -15px;
}

.mobile-view .product-details {
    /* padding: 1rem; */
    font-size: 12px;
    text-align: center;
    
}


.mobile-view .spacing-div {
    height: 10px
}


.mobile-view .gallery {
    display: flex;
    flex-direction: column; /* Arrange images in a column */
    margin: 1rem 0;
}

.mobile-view .gallery img {
    width: 100%; /* Make each image take up the full width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Adjust as needed to fit the container */
}


/* styling for Desktop product description */
.top-mobile-view h2 {
    font-family: 'PT Serif', serif; /* Replace with the desired font */
    font-size: 1.5rem; /* Adjust as needed */
    margin-bottom: 1rem; /* Space below the title */
}

.mobile-view h2{
    font-family: 'PT Serif', serif; /* Replace with the desired font */
    font-size: 2rem; /* Adjust as needed */
}

.mobile-view p {
    font-family: 'PT Serif';
    margin-bottom: 1rem; /* Space below each paragraph */
}

.mobile-view h3 {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 1rem
}


.mobile-view .italic {
    font-style: italic;
}

.mobile-view .italic-model-info {
    font-style: italic;
    margin-bottom: 1.5rem
}

.mobile-view .indented {
    font-family: 'PT Serif';
    text-indent: 2rem; /* Indentation for the first line */
}

.mobile-view .indented.italic {
    font-style: italic;
    text-indent: 2rem; /* Combine both indentation and italic styling */
}

.mobile-view .pants-extra-margin-bottom {
    margin-bottom: 15px;
}

.taller-break {
    display: block; /* Treat the <br /> like a block element */
    margin-top: 100px; /* Adjust to your desired height */
    margin-bottom: 100px;
  }

  .spacer {
    height: 10px; /* Adjust for desired spacing */
  }

/* Ensure the buy button spans the width of its container */
#product-component .shopify-buy__btn{
    width: 100%;
}

/* Hide the Shopify Buy Button select icon arrows */
.shopify-buy__select-icon {
    display: none;
}

/* Hide Shopify Buy Button option labels */
.shopify-buy__option-select__label {
    display: none;
}



/* Ensure the container is a flex container */
.shopify-buy__product__variant-selectors {
    display: flex;
    justify-content: space-between; /* Adjust spacing between items */
    gap: 20px; /* Optional: Adjust gap between items */
}

/* Ensure each option select wrapper takes up equal space */
.shopify-buy__option-select {
    flex: 1; /* Allow each option select to grow equally */
    max-width: 100%; /* Ensure they take up full width within their container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    margin-bottom: 10px; /* Add margin to increase the gap between selectors and the button */

}



/* Ensure select box fits within its parent and padding is managed */
.mobile-view .shopify-buy__option-select__select {
    font-family: Noto Sans;
    color:#000000;
    font-size: 10px;
    border-radius: 32px; /* Rounded corners */
    padding: 6px 12px;  /* Padding inside the selector; adjust right padding to create space for arrow */
    background-color: #ececec; /* Background color */
    border: 1px solid #757575; /* Border color */
    width: 100%; /* Make the selector take full width of its parent */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    -webkit-appearance: none; /* Remove default styling */
    -moz-appearance: none; /* Remove default styling */
    appearance: none; /* Remove default styling */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8" height="8"><path d="M21 5.176l-9.086 9.353L3 5.176.686 7.647 12 19.382 23.314 7.647 21 5.176z" fill="%23798c9c"/></svg>'); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Position the arrow */
}

.desktop-view .shopify-buy__option-select__select {
    font-family: Noto Sans;
    font-size: 12px;
    border-radius: 32px; /* Rounded corners */
    padding: 6px 12px;  /* Padding inside the selector; adjust right padding to create space for arrow */
    background-color: #ececec; /* Background color */
    border: 1px solid #757575; /* Border color */
    width: 100%; /* Make the selector take full width of its parent */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    -webkit-appearance: none; /* Remove default styling */
    -moz-appearance: none; /* Remove default styling */
    appearance: none; /* Remove default styling */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8" height="8"><path d="M21 5.176l-9.086 9.353L3 5.176.686 7.647 12 19.382 23.314 7.647 21 5.176z" fill="%23798c9c"/></svg>'); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Position the arrow */
}

/* Custom arrow using pseudo-elements */
.shopify-buy__option-select__select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 12px;
    height: 12px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21 5.176l-9.086 9.353L3 5.176.686 7.647 12 19.382 23.314 7.647 21 5.176z"/></svg>') no-repeat center center;
    pointer-events: none;
    transform: translateY(-50%);
}

/* Style for "Add to cart" button */
.mobile-view .shopify-buy__btn {
    font-family: Noto Sans;
    font-size: 10px;
    font-weight: 400;
    background-color: #000000; /* Black background */
    color: #ffffff; /* White text */
    border: none; /* Remove default border */
    border-radius: 32px; /* Rounded corners */
    padding: 6px 20px; /* Adjust padding */
    text-transform: uppercase; /* Uppercase text */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
}

/* Style for "Add to cart" button */
.desktop-view .shopify-buy__btn {
    font-family: Noto Sans;
    font-size: 12px;
    font-weight: 400;
    background-color: #000000; /* Black background */
    color: #ffffff; /* White text */
    border: none; /* Remove default border */
    border-radius: 32px; /* Rounded corners */
    padding: 6px 20px; /* Adjust padding */
    text-transform: uppercase; /* Uppercase text */
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
}

.shopify-buy__btn:hover {
    background-color: #333333; /* Darker black on hover */
}


/* Dropdown container margin */
.mobile-view .dropdown-container {
    margin: 20px 0;
    padding: 0px 10px;
}

/* Dropdown header flex properties */
.mobile-view .dropdown-header {
    display: flex;
    justify-content: center; /* Centers the title */
    align-items: center;
    cursor: pointer;
    position: relative; /* Needed for absolute positioning of the arrow */
    border-bottom: .5px solid #272727; /* Adds an underline */
    padding-bottom: 5px; /* Adds some space between the text and underline */
}

/* Dropdown header title styling */
.mobile-view .dropdown-header h4 {
    margin: 0;
    font-size: 10px;
    font-family: Noto Sans;
    color: #272727;
    font-weight:400;
    flex-grow: 1; /* Makes the title take up available space */
    text-align: center; /* Centers the text */
}

/* Arrow styling and positioning */
.mobile-view .arrow {
    position: absolute;
    right: 0; /* Pins the arrow to the right */
    transition: transform 0.2s ease-in-out;
    margin-right: 10px; /* Adds some space between the arrow and the right edge */
    width: 10px; /* Adjusts the width of the arrow */
    height: 10px; /* Adjusts the height of the arrow */
}

/* Hide the up arrow initially */
.mobile-view .arrow.up {
    display: none;
}


/* Show the correct arrow based on the state */
.mobile-view .dropdown-header.open .arrow.up {
    display: inline-block;
}

.mobile-view .dropdown-header.open .arrow.down {
    display: none;
}

/* Dropdown content styling */
.mobile-view .dropdown-content {
    padding: 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-family: Noto Sans;
}

.mobile-view .dropdown-text {
    /* font-style: italic; */
    font-size: 11px;
    margin-bottom: 0px;
}



/* Dropdown container margin */
.desktop-view .dropdown-container {
    margin: 20px 0;
    padding: 0px 0px;
}

/* Dropdown header flex properties */
.desktop-view .dropdown-header {
    display: flex;
    justify-content: center; /* Centers the title */
    align-items: center;
    cursor: pointer;
    position: relative; /* Needed for absolute positioning of the arrow */
    border-bottom: .8px solid #000000; /* Adds an underline */
    padding-bottom: 5px; /* Adds some space between the text and underline */
}

/* Dropdown header title styling */
.desktop-view .dropdown-header h4 {
    margin: 0;
    font-size: 12px;
    font-family: Noto Sans;
    color: #000000;
    font-weight:400;
    flex-grow: 1; /* Makes the title take up available space */
}

/* Arrow styling and positioning */
.desktop-view .arrow {
    position: absolute;
    right: 0; /* Pins the arrow to the right */
    transition: transform 0.2s ease-in-out;
    margin-right: 10px; /* Adds some space between the arrow and the right edge */
    width: 10px; /* Adjusts the width of the arrow */
    height: 10px; /* Adjusts the height of the arrow */
}

/* Hide the up arrow initially */
.desktop-view .arrow.up {
    display: none;
}


/* Show the correct arrow based on the state */
.desktop-view .dropdown-header.open .arrow.up {
    display: inline-block;
}

.desktop-view .dropdown-header.open .arrow.down {
    display: none;
}

/* Dropdown content styling */
.desktop-view .dropdown-content {
    padding: 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-family: Noto Sans;
}

.desktop-view .dropdown-text {
    margin-bottom: 0px;
    font-size: 14px;
}

.size-chart-image{
    width: 100%;
}


@media only screen and (max-width: 1200px) {
    .desktop-view .product-details {
        height:55vh;
    }
} 

/* Ensure the container is a flex container */
.snipcart-selectors-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px;
}

/* Style for selectors */
.mobile-view select {
  font-family: Noto Sans;
  color: #000000;
  font-size: 10px;
  border-radius: 32px;
  padding: 6px 12px;
  background-color: #ececec;
  border: 1px solid #757575;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8" height="8"><path d="M21 5.176l-9.086 9.353L3 5.176.686 7.647 12 19.382 23.314 7.647 21 5.176z" fill="%23798c9c"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.desktop-view select {
  font-family: Noto Sans;
  font-size: 12px;
  border-radius: 32px;
  padding: 6px 12px;
  background-color: #ececec;
  border: 1px solid #757575;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8" height="8"><path d="M21 5.176l-9.086 9.353L3 5.176.686 7.647 12 19.382 23.314 7.647 21 5.176z" fill="%23798c9c"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

/* Style for "Add to bag" button */
.mobile-view .snipcart-add-item {
  font-family: Noto Sans;
  font-size: 10px;
  font-weight: 400;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 32px;
  padding: 6px 20px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  margin-top: 0px;
}

.desktop-view .snipcart-add-item {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 400;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 32px;
  padding: 6px 20px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  margin-top: 0px;
}

.snipcart-add-item:hover {
  background-color: #333333;
}

.snipcart-add-item.out-of-stock {
  background-color: #cccccc;
  cursor: not-allowed;
}


