/* passwordProtection.module.css */

body, html {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}
.passwordProtection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100lvh;
}

.content {
  text-align: center;
  color: black;
}

.logo {
  position: absolute;
  top: 20px; /* Adjust this value to set the distance from the top of the page */
  left: 50%;
  transform: translateX(-50%); /* Centers the logo horizontally */
  max-width: 200px; /* Set a specific max-width */
  height: auto;
  object-fit: contain;
}

.logo img {
  max-height: 100%;
  max-width: 200px; /* Set a specific max-width */
  height: auto;
  object-fit: contain;
}



.passwordPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100lvh;
  flex-direction: column;
}

.emailFormContainer {
  margin-top: 4rem; /* Adjust this value to set the desired distance */
}




.passwordFormContainer {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.passwordButton {
  background-color: #ececec;
  color: #4a4a4a;
  border: none;
  border-radius: 18px;
  padding: 10px 20px;
  font-weight: 500;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),
              -5px -5px 10px rgba(255, 255, 255, 0.8);
  transition: all 0.2s ease;
}

.passwordButton:hover {
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.15),
              -6px -6px 12px rgba(255, 255, 255, 0.9);
}

.passwordButton:active {
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15),
              inset -2px -2px 5px rgba(255, 255, 255, 0.8);
  transform: translateY(1px);
}

.passwordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.passwordInput {
  width: 100%;
  border: none;
  border-radius: 20px;
  background-color: #ececec;
  color: #4a4a4a;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 15px;
  margin-bottom: 1rem;
  outline: none;
  font-family: 'Noto Sans';
  box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1),
              inset -2px -2px 5px rgba(255, 255, 255, 0.8);
  transition: all 0.2s ease;
}

.passwordInput:focus {
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.15),
              inset -3px -3px 6px rgba(255, 255, 255, 0.9);
}

.passwordInput::placeholder {
  color: #515151;
  opacity: 0.7;
  font-size: 13px;
  font-weight: 400;
}