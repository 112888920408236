:root {
  /* Breakpoints */
  --breakpoint-xs: 350px;  /* Extra small devices (small phones) */
  --breakpoint-sm: 576px;  /* Small devices (phones) */
  --breakpoint-md: 768px;  /* Medium devices (tablets) */
  --breakpoint-lg: 992px;  /* Large devices (small laptops) */
  --breakpoint-xl: 1200px; /* Extra large devices (large laptops/desktops) */
  
  /* Base flap measurements - mobile first (smallest size) */
  --flap-width: 12px;
  --flap-height: 25px;
  --flap-margin: 0px;
  --flap-border: 1px;
  --flap-border-radius: calc(var(--flap-width) * 0.17);
  --inner-border-radius: calc(var(--flap-width) * 0.05);
  --display-padding: 0px;
  
  /* Spacing system - reduce default gaps */
  --spacing-xs: 8px;
  --spacing-sm: 15px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* Main padding of board */
  --main-padding: 20px 30px;
  /* Board layout */
  --board-max-width: 100%;
  --board-padding: var(--spacing-sm);
  --row-gap: var(--spacing-xs);
  --column-gap: 5px;
  --header-gap: var(--spacing-xs);
  
  /* Typography */
  --font-family-primary: 'Arial', sans-serif;
  --font-weight-bold: 800;
  --header-font-size: 10px;
  --title-font-size: 20px;
  --flap-font-size: 13px;
  --letter-spacing: -0.5px;
  
  /* Colors */
  --flap-border-color: #1f1f1f;
  --flap-border-top-bottom-color: #272727;
  --flap-bg-color: rgb(15, 15, 15);
  
  /* Calculate widths */
  --char-total-width: calc(var(--flap-width) + (var(--flap-margin) * 2));
  
  /* Column configuration */
  --time-chars: 5;
  --from-chars: 5;
  --flight-chars: 6;
  --remarks-chars: 10;
  
  /* Calculate display widths */
  --time-width: calc((var(--char-total-width) * var(--time-chars)) + (var(--display-padding) * 2));
  --from-width: calc((var(--char-total-width) * var(--from-chars)) + (var(--display-padding) * 2));
  --flight-width: calc((var(--char-total-width) * var(--flight-chars)) + (var(--display-padding) * 2));
  --remarks-width: calc((var(--char-total-width) * var(--remarks-chars)) + (var(--display-padding) * 2));
  
  /* Border and shadow measurements */
  --flap-divider-width-top: 0.1px;
  --flap-divider-width-bottom: 0.1px;
  /* --text-shadow-offset: calc(var(--flap-height) * 0.02);
  --text-shadow-blur: calc(var(--flap-height) * 0.04); */
  /* --text-glow-blur: calc(var(--flap-height) * 0.1); */
  
  /* Add specific gap for double-height displays */
  --remarks-gap: 2px;  /* Gap between top and bottom remarks displays */
  
  /* Fluorescent lighting effects */
  --tube-glow-color: rgb(214, 214, 214);
  --tube-glow-spread: 15px;
  --tube-glow-intensity: 0.8;
  --tube-width: 8px;
  
  /* Title spacing variables */
  --title-margin: 7px;
  --title-padding: 8px;

  --split-flap-color: #303030;

  --top-flap-transform: translateY(53%);
  --bottom-flap-transform: translateY(-50%);
  
  /* Calculate minimum board width based on content - for all screen sizes */
  --total-content-width: calc(
    var(--time-width) + 
    var(--from-width) + 
    var(--flight-width) + 
    var(--remarks-width) + 
    (var(--column-gap) * 3)
  );
  --board-max-width: max(var(--total-content-width), 350px);
}

@font-face {
  font-family: 'Flipboard';
  src: url('https://fonts.cdnfonts.com/css/ds-digital') format('woff2');
}

/* Animation keyframes */
@keyframes flip {
  from { transform: rotateX(-0deg); }
  to { transform: rotateX(-90deg); }
}

@keyframes bflip {
  from { transform: rotateX(90deg); }
  to { transform: rotateX(0deg); }
}

/* Board layout */
.full-width-container {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 60px); /* Change min-height to height */
  background: #ececec;
  z-index: 0;
  overflow: hidden; /* Change from overflow-y: auto to overflow: hidden */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

/* Add brushed metal texture effect */
.full-width-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    /* Horizontal brushed lines */
    repeating-linear-gradient(
      90deg,
      transparent,
      transparent 1px,
      rgba(255,255,255,0.015) 1px,
      rgba(255,255,255,0.015) 2px
    ),
    /* Vertical subtle variation */
    repeating-linear-gradient(
      180deg,
      transparent,
      transparent 1px,
      rgba(0,0,0,0.02) 1px,
      rgba(0,0,0,0.02) 4px
    ),
    /* Diagonal texture for depth */
    linear-gradient(
      45deg,
      transparent 25%,
      rgba(255,255,255,0.02) 25%,
      rgba(255,255,255,0.02) 26%,
      transparent 26%,
      transparent 75%,
      rgba(255,255,255,0.02) 75%
    );
  background-size: 4px 100%, 100% 4px, 8px 8px;
  pointer-events: none;
  opacity: 0.4;
  mix-blend-mode: overlay;
  z-index: -1;
}

/* Board layout */
.departure-board {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  color: white;
  font-family: monospace;
  width: 100%;
  max-width: var(--board-max-width);
  position: relative;
  padding: calc(var(--board-padding) * 2);
  background: none;
  z-index: 1;
}

/* Board content wrapper */
.board-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0px;
}

/* Add subtle edge lighting effect */
.departure-board::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: linear-gradient(
    170deg,
    rgba(255,255,255,0.03) 0%,
    transparent 20%,
    transparent 80%,
    rgba(0,0,0,0.1) 100%
  ); */
  pointer-events: none;
}

.board-title,
.board-title-bottom {
  color: #000000;
  font-size: var(--title-font-size);
  font-weight: normal;
  text-align: left;
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: var(--board-max-width);
  padding: var(--title-padding) 0 var(--title-padding) 0;
  /* padding: var(--title-padding); */
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0 1px 1px rgba(203, 203, 203, 0.8);

}

.board-title {
  margin-bottom: var(--title-margin);
}

.board-title-bottom {
  margin-top: var(--title-margin);
}

/* Board content with neuromorphic effect */
.board-content {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0;
  position: relative;
  /* padding: calc(var(--spacing-md) * 2); */
  background: #ececec; /* Light neutral background for neuromorphism */
  border-radius: 10px;
  overflow: hidden;
  
  box-shadow: 
  /* Reduced outer shadow spread for subtlety */
  10px 10px 18px rgba(0, 0, 0, 0.15),
  /* Strengthened negative shadow for better balance */
  -8px -8px 16px rgba(255, 255, 255, 0.8),
  /* Refined inner shadows with reduced opacity */
  inset -2px -2px 6px rgba(255, 255, 255, 0.6),
  inset 2px 2px 6px rgba(0, 0, 0, 0.08);

/* Enhanced gradient for more apparent curvature */
background: linear-gradient(
  145deg,
  #f5f5f5 0%,
  #e0e0e0 100%
);
  

  /* main padding of board */
  padding: var(--main-padding);
  /* margin: 0 auto; */
  max-height: none;

}

/* Inner glow container */
/* .board-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow:
    inset 0 0 var(--tube-glow-spread) var(--tube-glow-color),
    inset 0 0 calc(var(--tube-glow-spread) / 2) rgba(254, 255, 194, 0.1);
  pointer-events: none;
  z-index: 1;
} */

/* Corner accents with contained glow */
/* .board-content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: calc(var(--tube-width) / 2) solid rgba(255, 255, 255, 0.03);
  box-shadow:
    inset 0 0 var(--tube-glow-spread) rgba(255, 255, 255, 0.1);
  pointer-events: none;
  z-index: 0;
} */

/* Grid system - tighter spacing */
.board-headers,
.board-row {
  display: grid;
  grid-template-columns: var(--time-width) var(--from-width) var(--flight-width) var(--remarks-width);
  gap: var(--column-gap);
  width: fit-content;
  margin: 0 auto;
  position: relative; /* For divider positioning */
}

/* Add dividers between rows */
.board-row {
  padding: var(--row-gap) 0;
  width: fit-content;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

/* .board-row:hover {
  transform: scale(1.01);
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
} */

/* Ensure the hover effect has rounded corners */
.board-row:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  right: -10px;
  bottom: 0;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 4px;
  z-index: -1;
}

/* Create divider lines between rows */
.board-row:not(:last-child)::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 100%,
    transparent
  );
  /* Add subtle shadow effect */
  box-shadow: 
    0 1px 2px rgba(0, 0, 0, 0.3),
    0 -0.5px 1px rgba(255, 255, 255, 0.05);
}

.board-headers {
  margin-bottom: var(--header-gap);
  position: relative;
  width: fit-content;
}

.board-headers::after {
  content: '';
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.15) 100%,
    transparent
  );
  /* Add more pronounced shadow for header divider */
  box-shadow: 
    0 1px 2px rgba(77, 77, 77, 0.4),
    0 -0.5px 1px rgba(255, 255, 255, 0.07);
}

.header-item {
  font-size: var(--header-font-size);
  color: #000000;
  text-align: left;
  width: 100%;
  padding: 0 var(--display-padding);
  margin-left: 0px;
  text-shadow: 0 1px 1px rgba(180, 180, 180, 0.8);
}

/* Row layout */
.board-row {
  min-height: calc(var(--flap-height) * 2);
  align-items: center;
}

.row-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* Update display stack styles */
.display-stack {
  display: flex;
  flex-direction: column;
  height: var(--flap-height); /* Single height by default */
}

.single-height {
  justify-content: center;
}

.double-height {
  height: calc(var(--flap-height) * 2); /* Exact height for two displays */
  gap: var(--remarks-gap); /* Explicit gap between displays */
}

/* Split flap display - enhance depth */
.split-flap-display {
  display: flex;
  position: relative;
  background: var(--split-flap-color);
  padding: var(--display-padding);
  border-radius: var(--flap-border-radius);
  /* Add these properties for depth */
  box-shadow: 
    /* REMOVING FOR PERFORMANCE */
    /* Inner shadow at top (stronger) */
    /* inset 0 3px 5px rgba(0,0,0,0.7), */
    /* Inner shadow at sides */
    /* inset 2px 0 4px rgba(0,0,0,0.5), */
    /* inset -2px 0 4px rgba(0,0,0,0.5), */
    /* Inner shadow at bottom (softer) */
    /* inset 0 -1px 3px rgba(0,0,0,0.4), */
    /* Subtle outer highlight to enhance recessed effect */
    /* 0 1px 1px rgba(255,255,255,0.05) */
    /* 0 2px 4px rgba(0,0,0,0.4) */
    /* Remove the stray semicolon */

    /* 0 2px 4px rgba(0,0,0,0.4),
    inset 0 1px 1px rgba(0,0,0,0.5),
    inset 0 -1px 3px rgba(0,0,0,0.5); */
  /* Add subtle gradient for dimension */
  /* Add subtle gradient for dimension */

  /* REMOVING FOR PERFORMANCE */
  /* background: linear-gradient(
    to bottom,
    rgba(60,60,60,1) 0%,
    var(--split-flap-color) 5%,
    var(--split-flap-color) 95%,
    rgba(50,50,50,1) 100%
  ); */
}

/* Enhance flap depth and texture */
.flap {
  width: var(--flap-width);
  height: var(--flap-height);
  border: var(--flap-border) solid var(--flap-border-color);
  margin: var(--flap-margin);
  font-size: var(--flap-font-size);
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  display: flex;
  flex-direction: column;
  background-color: var(--flap-bg-color);
  perspective: 1000px;
  overflow: hidden;
  border-radius: var(--flap-border-radius);
  position: relative;
  /* Add these properties for 3D effect */
  box-shadow: 
    /* Side shadows for depth */
    -1px 0 1px rgba(0,0,0,0.3),
    1px 0 1px rgba(0,0,0,0.3),
    /* Inner highlights and shadows */
    inset 0 1px 2px rgba(255,255,255,0.05),
    inset 0 -1px 2px rgba(0,0,0,0.2);
}

/* Add subtle texture to flaps */
.flap::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: 
    linear-gradient(45deg, 
      rgba(255,255,255,0.02) 25%, 
      transparent 25%, 
      transparent 75%, 
      rgba(255,255,255,0.02) 75%),
    linear-gradient(-45deg, 
      rgba(255,255,255,0.02) 25%, 
      transparent 25%, 
      transparent 75%, 
      rgba(255,255,255,0.02) 75%); */
  background-size: 4px 4px;
  pointer-events: none;
  opacity: 0.4;
  mix-blend-mode: overlay;
}

/* Enhance flap sections with depth */
.top,
.bottom {
  width: 100%;
  height: 50%;
  text-align: center;
  position: relative;
  overflow: hidden;
  /* Add gradient for 3D effect */
  background: linear-gradient(180deg, 
    rgba(20,20,20,1) 0%,
    rgba(25,25,25,1) 100%);
  border-radius: var(--inner-border-radius);
}

.top {
  border-bottom: var(--flap-divider-width-top) solid var(--flap-border-top-bottom-color);
  /* box-shadow: 
    inset 0 var(--text-shadow-offset) calc(var(--text-shadow-blur) * 1.5) rgba(255,255,255,0.1),
    0 calc(var(--text-shadow-offset) * -1) 2px rgba(0,0,0,0.9),
    inset 0 -1px 0 rgba(0,0,0,0.3); */
}

.bottom {
  border-top: var(--flap-divider-width-bottom) solid var(--flap-border-top-bottom-color);
  box-shadow: 0 -0.5px 0 var(--flap-border-top-bottom-color);
  /* box-shadow: 
    inset 0 calc(var(--text-shadow-offset) * -1) calc(var(--text-shadow-blur) * 1.5) rgba(0,0,0,0.4),
    inset 0 1px 0 rgba(0,0,0,0.3); */
}

/* Enhanced flap states with depth */
.top-flap-visible,
.top-flap-queued,
.top-static-flap,
.bottom-flap-visible,
.bottom-flap-queued,
.bottom-static-flap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--split-flap-color);
  border-radius: var(--inner-border-radius);
  /* Add subtle inner shadow to the inside of the flap (PERFORMANCE TEST)*/
  /* box-shadow: 
    inset 0 1px 2px rgba(255,255,255,0.05),
    inset 0 -1px 2px rgba(0,0,0,0.3); */
}

/* Enhanced text styling with better depth */
.top span,
.bottom span {
  display: block;
  position: relative;
  height: var(--flap-height);
  line-height: var(--flap-height);
  color: white;
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  letter-spacing: var(--letter-spacing);
  -webkit-font-smoothing: antialiased;
}

.top span {
  /* Position the text so its bottom half is at the bottom of the top flap */
  position: absolute;
  bottom: 0%;
  width: 100%;
  transform: var(--top-flap-transform);
  
}

.bottom span {
  /* Position the text so its top half is at the top of the bottom flap */
  position: absolute;
  top: 0%;
  width: 100%;
  transform: var(--bottom-flap-transform);
  
}

/* Animation states */
.bottom-flap-queued {
  z-index: 2;
  transform: rotateX(90deg);
}

/* SPEED OF SPLIT FLAP DISPLAY SECONDS */
.top-flap-animation {
  animation: flip 0.13s ease-in;
  transform-origin: bottom;
}

.bottom-flap-animation {
  animation: bflip 0.13s ease-in;
  transform-origin: top;
  animation-delay: 0s;
}

/* Noise overlay */
svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  mix-blend-mode: soft-light;
  filter: contrast(320%) brightness(250%);
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.sound-toggle-wrapper {
  position: absolute;
  top: 70px;
  right: 50px;
  display: flex;
  flex-direction: column;
}

.sound-toggle-wrapper button {
  width: 140px;
  border: 1px solid white;
  height: 40px;
  font-weight: bold;
  transition: 0.2s;
  cursor: pointer;
  margin: 0px 5px;
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
}

/* .sound-toggle-wrapper button:hover {
  color: white;
  background-color: black;
  transition: 0.2s;
}

.sound-toggle-wrapper button:active {
  transform: scale(0.95);
} */

/* Keep yellow color only for remarks with enhanced visibility */
.row-item:last-child .display-stack .split-flap-display span {
  color: #FFEB69;
  /* REMOVING FOR PERFORMANCE */
  /* text-shadow: 
    0 calc(var(--text-shadow-offset) * -1) 0 rgba(0,0,0,0.7),
    0 var(--text-shadow-offset) 0 rgba(255,255,255,0.1),
    0 0 var(--text-glow-blur) rgba(255,235,105,0.3); */
}




/* Extra small devices (tiny phones, less than 350px) */
@media (max-width: 349px) {
  :root {
    --flap-width: 9px;
    --flap-font-size: 10px;
    --flap-height: 20px;
    --header-font-size: 12px;
    --title-font-size: 16px;
    --board-padding: var(--spacing-md);
    --row-gap: var(--spacing-sm);
    --column-gap: 1px;
    --flap-margin: 1px;
    --display-padding: 0px;
    --flap-divider-width-top: 0.1px;
    --flap-divider-width-bottom: 0.1px;
    --title-margin: 10px;
    --title-padding: 12px;
    --board-max-width: max(var(--total-content-width), 650px);
  }
  
  .departure-board {
    padding: 5px;
    margin-top: 50px;
  }
  
  .header-item {
    font-size: var(--header-font-size);
    padding: 0 1px;
    margin-left: 1px;
  }
}

/* Small phones (350px to 414px) */
@media (min-width: 200px) and (max-width: 349px) {
  :root {
    --flap-width: 11px;
    --flap-height: 23px;
    --flap-font-size: 12px;
    --header-font-size: 12px;
    --title-font-size: 23px;
    --board-padding: var(--spacing-md);
    --row-gap: var(--spacing-sm);
    --column-gap: 2px;
    --flap-margin: 0.1px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 0px;
    --top-flap-transform: translateY(53%);
    --bottom-flap-transform: translateY(-48%);
    --title-margin: 12px;
    --title-padding: 10px;
    --board-max-width: max(var(--total-content-width), 650px);
    --main-padding: 18px 18px;

    --flap-border-radius: calc(var(--flap-width) * 0.15);
    --inner-border-radius: calc(var(--flap-width) * 0.02);
  }
}

/* Small phones (350px to 414px) */
@media (min-width: 350px) and (max-width: 414px) {
  :root {
    --flap-width: 11px;
    --flap-height: 21px;
    --flap-font-size: 12px;
    --header-font-size: 12px;
    --title-font-size: 23px;
    --board-padding: var(--spacing-md);
    --row-gap: 10px;
    --column-gap: 2px;
    --flap-margin: 0.1px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 0px;
    --top-flap-transform: translateY(53%);
    --bottom-flap-transform: translateY(-48%);
    --title-margin: 10px;
    --title-padding: 7px;
    --board-max-width: max(var(--total-content-width), 650px);
    --main-padding: 10px 18px;

    --flap-border-radius: calc(var(--flap-width) * 0.15);
    --inner-border-radius: calc(var(--flap-width) * 0.02);
  }
}

/* Medium phones (415px to 469px) */
@media (min-width: 415px) and (max-width: 469px) {
  :root {
    --flap-width: 12.5px;
    --flap-height: 24px;
    --flap-font-size: 13px;
    --header-font-size: 12px;
    --title-font-size: 25px;
    --board-padding: var(--spacing-md);
    --row-gap: var(--spacing-sm);
    --column-gap: 3px;
    --flap-margin: 0.1px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 0px;
    --top-flap-transform: translateY(53%);
    --bottom-flap-transform: translateY(-48%);
    --title-margin: 12px;
    --title-padding: 10px;
    --board-max-width: max(var(--total-content-width), 650px);
    --main-padding: 18px 18px;

    --flap-border-radius: calc(var(--flap-width) * 0.15);
    --inner-border-radius: calc(var(--flap-width) * 0.02);
  }
}

/* Large phones (470px to 575px) */
@media (min-width: 470px) and (max-width: 575px) {
  :root {
    --flap-width: 14px;
    --flap-height: 26px;
    --flap-font-size: 15px;
    --header-font-size: 15px;
    --title-font-size: 28px;
    --board-padding: var(--spacing-md);
    --row-gap: var(--spacing-sm);
    --column-gap: 2px;
    --flap-margin: 0.1px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 0px;
    --top-flap-transform: translateY(53%);
    --bottom-flap-transform: translateY(-48%);
    --title-margin: 12px;
    --title-padding: 10px;
    --board-max-width: max(var(--total-content-width), 650px);
    --main-padding: 20px 20px;
  }
}

/* Small tablets and landscape phones (576px to 767px) */
@media (min-width: 576px) and (max-width: 767px) {
  :root {
    --flap-width: 16px;
    --flap-height: 30px;
    --flap-font-size: 19px;
    --header-font-size: 16px;
    --title-font-size: 30px;
    --board-padding: var(--spacing-md);
    --row-gap: var(--spacing-sm);
    --column-gap: 4px;
    --flap-margin: .5px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 1px;
    --title-margin: 12px;
    --title-padding: 10px;
    --board-max-width: max(var(--total-content-width), 650px);
  }
}

/* Medium devices (tablets, 768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  :root {
    --flap-width: 15px;
    --flap-height: 26px;
    --flap-font-size: 17px;
    --header-font-size: 17px;
    --title-font-size: 33px;
    --board-padding: 0px;
    --row-gap: 7px;
    --column-gap: 8px;
    --flap-margin: .5px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 1px;
    --total-content-width: calc(
      var(--time-width) + 
      var(--from-width) + 
      var(--flight-width) + 
      var(--remarks-width) + 
      (var(--column-gap) * 3)
    );
    --board-max-width: max(var(--total-content-width), 1200px);
    --title-margin: 18px;
    --title-padding: 5px;
  }

  /* .board-headers,
  .board-row {
    gap: var(--spacing-sm);
  } */
}

/* Large devices (small laptops, 992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  :root {
    --flap-width: 15px;
    --flap-height: 26px;
    --flap-font-size: 17px;
    --header-font-size: 17px;
    --title-font-size: 33px;
    --board-padding: 0px;
    --row-gap: 7px;
    --column-gap: 8px;
    --flap-margin: .5px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 1px;
    --total-content-width: calc(
      var(--time-width) + 
      var(--from-width) + 
      var(--flight-width) + 
      var(--remarks-width) + 
      (var(--column-gap) * 3)
    );
    --board-max-width: max(var(--total-content-width), 1200px);
    --title-margin: 18px;
    --title-padding: 5px;
  }
}

/* Extra large devices (laptops/desktops, 1200px to 1399px) */
@media (min-width: 1200px) and (max-width: 1399px) {
  :root {
    --flap-width: 15px;
    --flap-height: 26px;
    --flap-font-size: 17px;
    --header-font-size: 17px;
    --title-font-size: 33px;
    --board-padding: 0px;
    --row-gap: 7px;
    --column-gap: 8px;
    --flap-margin: .5px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 1px;
    --total-content-width: calc(
      var(--time-width) + 
      var(--from-width) + 
      var(--flight-width) + 
      var(--remarks-width) + 
      (var(--column-gap) * 3)
    );
    --board-max-width: max(var(--total-content-width), 1200px);
    --title-margin: 18px;
    --title-padding: 5px;
  }
}

/* Extra extra large devices (large desktops, 1400px and up) */
@media (min-width: 1400px) {
  :root {
    --flap-width: 15px;
    --flap-height: 26px;
    --flap-font-size: 17px;
    --header-font-size: 17px;
    --title-font-size: 33px;
    --board-padding: 0px;
    --row-gap: 7px;
    --column-gap: 8px;
    --flap-margin: .5px;
    --display-padding: 0px;
    --flap-divider-width-top: 1px;
    --flap-divider-width-bottom: 1px;
    --total-content-width: calc(
      var(--time-width) + 
      var(--from-width) + 
      var(--flight-width) + 
      var(--remarks-width) + 
      (var(--column-gap) * 3)
    );
    --board-max-width: max(var(--total-content-width), 1200px);
    --title-margin: 18px;
    --title-padding: 5px;
  }
  
  .board-content {
    max-width: var(--board-max-width);
    margin: 0 auto;
  }
}

