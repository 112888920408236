/* emailForm.module.css */

.emailFormContainer {
    width: 100%;
    padding: 1rem 0;
}

.emailForm {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d3d3d3; /* Bottom border to underline the entire form */
    width: 100%;
}

.emailInput {
    flex: 1; /* Takes up all available space */
    border: none;
    background: transparent; /* Removes the background */
    color: #fff; /* Adjust the color to match your design */
    font-size: 13px;
    padding: 0.5rem 0;
    outline: none; /* Removes the outline on focus */
    font-family: 'Noto Sans';
}

.emailInput::placeholder {
    color: #d3d3d3; /* Adjust the placeholder color */
    /* font-style: italic;  */
}

.subscribeButton {
    border: none;
    background: transparent;
    color: #fff; /* Adjust the color to match your design */
    font-weight: 400;
    font-size: 13px;
    padding: 0.5rem 0;
    cursor: pointer;
    outline: none; /* Removes the outline on focus */
    margin-left: 1rem; 
    margin-left: auto; /* Pushes the button to the right */
}

.thankYouMessage{
    color: #ebebeb;
    font-style:normal;
    /* padding: 0.5rem 0; */
}

/* .email-form-container::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #d3d3d3;
    position: absolute;
    bottom: 0;
} */

