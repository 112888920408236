
/* body, html {
  overflow: hidden;
  } */

  /* html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  } */

.mainPage {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: hidden;
}

.foregroundContainer {
  position: fixed; /* Stays within the normal flow but layered above background */
  top: 0;          /* Align to the top */
  left: 0; 
  z-index: 2; /* Higher than the backgroundContainer */
  width: 100vw;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3); /* White with 80% opacity */
  backdrop-filter: blur(2px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(2px); /* For Safari support */
  overflow: hidden;
  /* transform: translateY(-30px); */
}

/* container for floating objects background */
.backgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensures it's below foreground content */
  overflow: hidden;

}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
}

.centerHeading {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 1rem;
}

.centerList {
  list-style: none;
  padding: 0;
  
}

.centerListItems {
  margin: 0.5rem 0;
  cursor: pointer;
  font-size: 16px;
  
  text-decoration: none; /* Remove underline */
}

.variant-link {
  text-decoration: none !important; /* Force removal */
  color: inherit; /* Ensure consistent color */
}

a {
  text-decoration: none;
  color: inherit;
}

.separator {
  margin: 0 0.5rem;
  color: grey;
}

.arrow {
  margin-top: 1rem;
  cursor: pointer;
}

.arrowIcon {
  font-size: 1.5rem;
}

