/* emailAndNameForm.module.css */

.emailFormContainer {
    width: 100%;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emailForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.nameInput,
.emailInput {
    width: 100%;
    border: none;
    border-radius: 20px;
    background-color: #ececec;
    color: #4a4a4a;
    font-size: 13px;
    font-weight: 500;
    padding: 12px 15px;
    margin-bottom: 1rem;
    outline: none;
    font-family: 'Noto Sans';
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1),
                inset -2px -2px 5px rgba(255, 255, 255, 0.8);
    transition: all 0.2s ease;
}

.nameInput:focus,
.emailInput:focus {
    box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.15),
                inset -3px -3px 6px rgba(255, 255, 255, 0.9);
}

.nameInput::placeholder,
.emailInput::placeholder {
    color: #515151;
    opacity: 0.7;
    font-size: 13px;
    font-weight: 400;
}

.subscribeButton {
    background-color: #ececec;
    color: #4a4a4a;
    font-size: 13px;
    font-weight: 400;
    border: none;
    border-radius: 18px;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    margin-top: 1rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1),
                -5px -5px 10px rgba(255, 255, 255, 0.8);
    transition: all 0.2s ease;
}

.subscribeButton:hover {
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.15),
                -6px -6px 12px rgba(255, 255, 255, 0.9);
}

.subscribeButton:active {
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.15),
                inset -2px -2px 5px rgba(255, 255, 255, 0.8);
    transform: translateY(1px);
}

.thankYouMessage{
    font-family: Noto Sans;
    font-weight: 300;
    font-size: 15px;
    margin-top: 50px;
}